import { type FC } from 'react';

import { FlowType } from '../ProgramSubmissionFlow/types';
import BaseSubmissionError from './BaseSubmissionError';
import SubmissionErrorPopup from './SubmissionErrorPopup';

type Props = {
  onCtaClick: () => void;
  flowType: FlowType;
};

const SubmissionError: FC<Props> = ({ onCtaClick, flowType }) => {
  console.log(flowType);
  const SubmissionErrorComponent = flowType === FlowType.POPUP ? SubmissionErrorPopup : BaseSubmissionError;

  return <SubmissionErrorComponent onCtaClick={onCtaClick} />;
};

export default SubmissionError;
