import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import styles from './TCPA.module.css';

type Props = {
  result?: VoyagerResult;
};

const TCPA: FC<Props> = ({ result }) => (
  <div className={styles.piiConfirmationTermsAndConditions}>
    <div className={styles.piiConfirmationTermsAndConditionsTitle}> Terms and Conditions </div>

    <p dangerouslySetInnerHTML={{ __html: result?.school?.config?.tcpa ?? '' }} />
  </div>
);

export default TCPA;
