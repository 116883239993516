import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import { FlowType } from '../ProgramSubmissionFlow/types';
import NotQualifiedDrawer from './NotQualifiedDrawer';
import NotQualifiedPopup from './NotQualifiedPopup';

type Props = {
  flowType: FlowType;
  result: VoyagerResult;
  onCtaClick: () => void;
};

const NotQualified: FC<Props> = ({ flowType, result, onCtaClick }) =>
  flowType === FlowType.POPUP ? (
    <NotQualifiedPopup result={result} onCtaClick={onCtaClick} />
  ) : (
    <NotQualifiedDrawer onCtaClick={onCtaClick} />
  );

export default NotQualified;
