import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import Pill from '../components/Pill';
import type { UserPreferencesAndMilitary } from '../types';
import styles from './UserPreferencesPills.module.css';

type Props = {
  result: VoyagerResult;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
};

function findObjectByLabel(array: UserPreferencesAndMilitary, label: string) {
  return array?.find((obj) => obj.label === label);
}

export const showApplyForFree = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'Affordability') !== undefined &&
  result?.school?.additional?.isApplicationFeeOnlineWaived;

export const showMilitary = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'militaryAffiliation')?.key === 'true' &&
  result?.school?.additional?.isMilitaryFriendly;

export const showAccredited = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'Accreditation') !== undefined &&
  result?.school?.additional?.accreditator !== null;

export const showTransferCredits = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'Ability to transfer credits') !== undefined &&
  result?.school?.additional?.areTransferCreditsAccepted;

export const showNoActSat = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'Admissions rate') !== undefined &&
  (result?.school?.additional?.satOrActAdmissionsRequirements === 'Considered but not required' ||
    result?.school?.additional?.satOrActAdmissionsRequirements === 'Not used if submitted');

export const showFlexible = (result: VoyagerResult, userPreferencesAndMilitary: UserPreferencesAndMilitary) =>
  findObjectByLabel(userPreferencesAndMilitary, 'Flexibility') !== undefined &&
  result?.school?.additional?.doesOfferDistanceEducationCourses === 'Yes';

const UserPreferencesPills: FC<Props> = ({ result, userPreferencesAndMilitary }) => {
  const labels = [];

  if (showMilitary(result, userPreferencesAndMilitary)) {
    labels.push('Military Friendly');
  }
  if (showApplyForFree(result, userPreferencesAndMilitary)) {
    labels.push('Apply for Free');
  }
  if (showAccredited(result, userPreferencesAndMilitary)) {
    labels.push('Accredited');
  }
  if (showTransferCredits(result, userPreferencesAndMilitary)) {
    labels.push('Transfer Credits');
  }
  if (showNoActSat(result, userPreferencesAndMilitary)) {
    labels.push('No ACT/SAT');
  }
  if (showFlexible(result, userPreferencesAndMilitary)) {
    labels.push('Flexible');
  }

  const hasPills = labels.length > 0;
  return hasPills ? (
    <section className={styles.hasPills}>
      {labels.map((label) => (
        <Pill key={label} color="#79B8DF">
          {label}
        </Pill>
      ))}
    </section>
  ) : null;
};

export default UserPreferencesPills;
