export type FormError = { field: string }[];
export enum StepTypes {
  PII_CONFIRMATION = 'PII_CONFIRMATION',
  ADDITIONAL_QUESTIONS = 'ADDITIONAL_QUESTIONS',
  EXIT_STRATEGY = 'EXIT_STRATEGY',
  LOADING = 'LOADING',
  PROGRAM_SELECT = 'PROGRAM_SELECT',
  NOT_QUALIFIED = 'NOT_QUALIFIED',
  ERROR = 'ERROR',
}
