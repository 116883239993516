import { type FC } from 'react';

import FormCtaButtonGroup from '../../FormCtaButtonGroup';
import styles from './FormCtaSection.module.css';

type Props = {
  onSubmitClick?: () => void;
  onEditClick?: () => void;
  onEditConfirmClick?: () => void;
  isEditing?: boolean;
  canEdit?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
};

const FormCtaSection: FC<Props> = ({
  onSubmitClick,
  onEditClick,
  onEditConfirmClick,
  isEditing = false,
  canEdit = true,
  isLoading = false,
  isDisabled,
}) => (
  <div className={styles.ctaSection}>
    <div className={styles.ctaSection}>
      <FormCtaButtonGroup
        isLoading={isLoading}
        onSubmitClick={onSubmitClick}
        onEditClick={onEditClick}
        onEditConfirmClick={onEditConfirmClick}
        isEditing={isEditing}
        canEdit={canEdit}
        isDisabled={isDisabled}
      />
      <div className={styles.piiInfoDisclaimerHeader}>
        Your information will never be shared with a school without your consent.
      </div>
      <div className={styles.piiInfoDisclaimerText}>
        By selecting &quot;Learn More&quot; I confirm that the information provided on this form is accurate and
        complete and I agree to receive email communications, phone calls, and SMS/Text Messages from these schools at
        the numbers provided above, including my wireless numbers if provided. Contact methods may include calls or
        texts made using automated technology or pre-recorded voice messages. I understand that my consent is not a
        condition of purchasing services from these schools.
      </div>
    </div>
  </div>
);

export default FormCtaSection;
