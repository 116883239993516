import { v4 as uuidv4 } from 'uuid';

import { computeDcsConsolidation, programDifference } from '@core/hooks/useDCSConsolidation';
import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import { validateZip, type ValidateZipSuccess } from '@core/services/leadDelivery';
import type { VoyagerResult } from '@core/ts/results';
import { type BuildLeadObjectInputs } from '@core/utils/buildLeadObject';
import getAllCandidates from '@core/utils/spotlight/getAllCandidates';
import getSpotlightRulesForDcs from '@core/utils/spotlight/getSpotlightRulesForDcs';

import sortHitsByStateERPI from '../utils/sortHitsByStateERPI';
import fetchAlgoliaMatches from './fetchAlgoliaHits';
import fetchSchoolConfigRegistry from './fetchSchoolConfigRegistry';
import fetchSchoolSchemaRegistry from './fetchSchoolSchemaRegistry';

type Params = {
  dcs: {
    dcsDegrees: string[];
    dcsCategories: string[];
    dcsSubjects: string[];
  };
  inputs: VoyagerInputs;
  configFilters: string[];
};

export type BestMatchExperienceData = {
  spotlightMatch: VoyagerResult | undefined;
  recommenderMatches: VoyagerResult[];
  exactMatches: VoyagerResult[];
  relatedMatches: VoyagerResult[];
};

type GetBestMatchExperienceData = (params: Params) => Promise<BestMatchExperienceData>;

const getBestMatchExperienceData: GetBestMatchExperienceData = async ({ dcs, inputs, configFilters }) => {
  const { stateAbbr } = (await validateZip(inputs?.zip?.value)) as ValidateZipSuccess;

  const { recommendedHits, exactHits, relatedHits } = await fetchAlgoliaMatches({
    dcs,
    inputs,
    configFilters,
  });

  // Sort exact matches by state ERPI
  const sortedExactMatches = sortHitsByStateERPI(exactHits, stateAbbr);
  const sortedRelatedMatches = sortHitsByStateERPI(relatedHits, stateAbbr);

  // If no recommender matches are found, use the first 2 exact matches, but if 1 is found, use the first 1
  const nonTwoUExactMatches = sortedExactMatches
    .filter(({ program }) => program.providerName !== 'TwoU')
    .slice(0, recommendedHits.length === 1 ? 1 : 2);

  const recommenderMatches = [...recommendedHits, ...nonTwoUExactMatches];

  const exactMatchesAfterRecommender = programDifference(sortedExactMatches, recommenderMatches);

  // DCS Consolidation
  const matchesBorrowedFromRelatedMatches = computeDcsConsolidation<VoyagerResult>({
    degree: dcs?.dcsDegrees?.[0],
    exactMatches: exactMatchesAfterRecommender,
    relatedMatches: sortedRelatedMatches,
  });

  const exactMatches = [...exactMatchesAfterRecommender, ...matchesBorrowedFromRelatedMatches];
  const relatedMatches = programDifference(sortedRelatedMatches, matchesBorrowedFromRelatedMatches);

  const spotlightSchoolCandidates = getAllCandidates({
    inputs,
    rules: getSpotlightRulesForDcs({
      degree: dcs?.dcsDegrees?.[0],
      category: dcs?.dcsCategories?.[0],
      subject: dcs?.dcsSubjects?.[0],
    }),
    currentState: stateAbbr,
  });

  const spotlightMatch = spotlightSchoolCandidates
    .map(({ schoolId }) => exactMatches.find((hit) => hit.school.id === schoolId))
    ?.filter(Boolean)?.[0];

  const allResults = [
    ...(spotlightMatch ? [spotlightMatch] : []),
    ...recommenderMatches,
    ...exactMatches,
    ...relatedMatches,
  ];

  // get unique school configs
  const allSchoolIds = allResults.map(({ school }) => school.id);
  const uniqueSchoolIds = Array.from(new Set(allSchoolIds));
  const schoolConfigRegistry = await fetchSchoolConfigRegistry(uniqueSchoolIds);

  // get unique school schemas
  const schemaRegistry = await fetchSchoolSchemaRegistry({
    results: allResults,
    inputs: inputs as BuildLeadObjectInputs,
  });

  // Generate eventing uuids
  const spotlightListId = uuidv4();
  const recommenderListId = uuidv4();
  const exactListId = uuidv4();
  const relatedListId = uuidv4();

  // Attach eventing and school config to each result
  const attachEventingAndSchoolConfig =
    (listId: string) =>
    (result: VoyagerResult, index: number = 1): VoyagerResult => ({
      ...result,
      school: {
        ...result.school,
        config: schoolConfigRegistry[result.school.id],
      },
      eventing: {
        ...result.eventing,
        listId,
        position: index,
        viewCorrelationId: uuidv4(),
      },
      leadSchema: schemaRegistry[result.program.id],
    });

  return {
    spotlightMatch: spotlightMatch ? attachEventingAndSchoolConfig(spotlightListId)(spotlightMatch) : undefined,
    recommenderMatches: recommenderMatches.map(attachEventingAndSchoolConfig(recommenderListId)),
    exactMatches: exactMatches.map(attachEventingAndSchoolConfig(exactListId)),
    relatedMatches: relatedMatches.map(attachEventingAndSchoolConfig(relatedListId)),
  };
};

export default getBestMatchExperienceData;
