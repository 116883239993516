import { type FC } from 'react';

import type { ApplicationCta } from '@core/blocks/best-match-experience/types';

import ApplicationCTA from '../../ApplicationCTA';
import ExitStrategyCongratsBanner from '../ExitStrategyCongratsBanner';
import styles from './ExitStrategySidebar.module.css';

type Props = {
  firstName: string;
  currentResultApplicationCta: ApplicationCta;
  isCurrentResultApplicationCtaLoading?: boolean;
};

const ExitStrategySidebar: FC<Props> = ({
  firstName,
  isCurrentResultApplicationCtaLoading,
  currentResultApplicationCta,
}) => (
  <section className={styles.exitStrategyContainer}>
    <div className={styles.congratsBanner}>
      <ExitStrategyCongratsBanner firstName={firstName} />
    </div>
    <div className={styles.cta}>
      <ApplicationCTA cta={currentResultApplicationCta} isLoading={isCurrentResultApplicationCtaLoading} />
    </div>
  </section>
);

export default ExitStrategySidebar;
