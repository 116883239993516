import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import UserPreferencesPills from '../../UserPreferencesPills';
import type { UserPreferencesAndMilitary } from '../../types';
import styles from './DrawerExperienceHeader.module.css';

type Props = {
  result: VoyagerResult;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
};

const DrawerExperienceHeader: FC<Props> = ({ result, userPreferencesAndMilitary }) => (
  <header className={styles.drawerExperienceHeader}>
    <h2 className={styles.programName}>{result?.program?.name}</h2>
    <div className={styles.schoolInfoSection}>
      <div className={styles.schoolInfoSectionColumn}>
        <div className={styles.schoolLogo}>
          <img
            src={`https://simple-storage-server.highereducation.com/${result?.school?.slug}.png?w=130&h=80`}
            alt={result?.school?.name}
          />
        </div>
        <div className={styles.nameAndLocationSection}>
          <div className={styles.schoolName}>{result?.school?.name}</div>
          {result?.school?.city && (
            <div className={styles.locationInfo}>
              {result?.school?.city}, {result?.school?.state}
            </div>
          )}
        </div>
      </div>
      <div className={styles.dataPillsSection}>
        <UserPreferencesPills result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />
      </div>
    </div>
  </header>
);

export default DrawerExperienceHeader;
