import { useState, type FC, type ReactNode, type MouseEvent } from 'react';
import { HiMapPin } from 'react-icons/hi2';

import UserPreferencesPills from '../../UserPreferencesPills';
import styles from './BaseCard.module.css';
import type { ProgramCardProps } from './types';

type Props = {
  children?: ReactNode;
} & ProgramCardProps;

const BaseCard: FC<Props> = ({ result, children, userPreferencesAndMilitary }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const resultSnippet = `${result?.program?.snippet.substring(0, showMore ? result?.program?.snippet?.length : 100)}${
    showMore ? '' : '...'
  }`;

  const handleDescriptionToggle = (event: MouseEvent) => {
    event.stopPropagation();
    setShowMore(!showMore);
  };

  return (
    <div className={styles.programCard}>
      <section>
        <header className={styles.header}>
          <img
            src={`https://simple-storage-server.highereducation.com/${result.school.slug}.png?w=130&h=80`}
            alt={result.school.name}
            className={styles.logo}
          />
          <div className={styles.schoolNameAndLocation}>
            <h4>{result.school.name}</h4>
            {result?.school?.city && (
              <p>
                <HiMapPin />
                {result.school.city}, {result.school.state}
              </p>
            )}
          </div>
        </header>
        <UserPreferencesPills result={result} userPreferencesAndMilitary={userPreferencesAndMilitary} />

        <div className={styles.programInfo}>
          <h3 className={styles.programName}>{result.program.name}</h3>

          <p className={styles.programDescription}>
            {resultSnippet}{' '}
            <button className={showMore && styles.hideReadButton} onClick={handleDescriptionToggle} type="button">
              {showMore ? 'Show Less' : 'Read More'}
            </button>
          </p>
        </div>
      </section>

      <div className={styles.programStatsAndCta}>
        <div className={styles.programStats} />
        {children}
      </div>
    </div>
  );
};

export default BaseCard;
