import { type IdSchema } from '@rjsf/utils';
import { type FC, type Dispatch, type SetStateAction, useEffect } from 'react';
import { HiXMark } from 'react-icons/hi2';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import { type VoyagerResult } from '@core/ts/results';

import type { ApplicationCta, UserPreferencesAndMilitary } from '../../types';
import ProgramSubmissionFlow from '../ProgramSubmissionFlow';
import { FlowType } from '../ProgramSubmissionFlow/types';
import { StepTypes } from '../Steps/types';
import Popup from '../ui/Popup';
import styles from './PopupExperience.module.css';

type Props = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  spotlightMatch: VoyagerResult;
  recommenderMatches: VoyagerResult[];
  userInputs: VoyagerInputs;
  prqSchema?: IdSchema;
  result: VoyagerResult;
  onProgramCtaClick: (result: VoyagerResult) => void;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  onEdit: (result: VoyagerResult) => void;
  publisher: string;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  onPopupClose: () => void;
  degree: string;
  subject: string;
};

const PopupExperience: FC<Props> = ({
  spotlightMatch,
  recommenderMatches,
  result,
  show,
  setShow,
  userInputs,
  prqSchema,
  onProgramCtaClick,
  onFormSubmit,
  onEdit,
  publisher,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  userPreferencesAndMilitary,
  onPopupClose,
  degree,
  subject,
}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [show]);

  const onFlowExit = async () => {
    setShow(false);
  };

  return (
    <Popup show={show}>
      <div className={styles.popup}>
        <button className={styles.closeButton} type="button" onClick={onPopupClose}>
          <HiXMark size={10} strokeWidth={1} />
        </button>
        <ProgramSubmissionFlow
          isHeclidLoading={isHeclidLoading}
          flowType={FlowType.POPUP}
          onProgramCtaClick={onProgramCtaClick}
          prqSchema={prqSchema}
          userInputs={userInputs}
          result={result}
          onFormSubmit={onFormSubmit}
          onFlowExit={onFlowExit}
          recommenderMatches={recommenderMatches}
          spotlightMatch={spotlightMatch}
          initialStep={StepTypes.PROGRAM_SELECT}
          onEdit={onEdit}
          publisher={publisher}
          currentResultApplicationCta={currentResultApplicationCta}
          isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
          degree={degree}
          subject={subject}
        />
      </div>
    </Popup>
  );
};

export default PopupExperience;
