import { Children, type ReactElement, type ReactNode, type FC } from 'react';

import { StepTypes } from './types';

type StepProps = {
  stepId: StepTypes;
  children: ReactNode;
};

export const Step = ({ children, stepId }: StepProps) => <span id={stepId}>{children}</span>;

type StepsProps = {
  currentStepId: StepTypes;
  children: ReactNode;
};
const Steps: FC<StepsProps> = ({ children, currentStepId }) => {
  const steps = Children.toArray(children) as ReactElement<StepProps>[];

  // grab the current step by id
  const currentStep = steps.find((child) => child.props.stepId === currentStepId);

  return <>{currentStep}</>;
};

export default Steps;
