import { type FC, useEffect, useState } from 'react';

import { type VoyagerInputs } from '@core/reducers/inputsSlice';
import type { VoyagerResult } from '@core/ts/results';

import type { ApplicationCta, UserPreferencesAndMilitary } from '../../types';
import AdditionalQuestions2 from '../AdditionalQuestions2';
import ExitStrategyComponent from '../ExitStrategy';
import NotQualified from '../NotQualified';
import PiiConfirmation from '../PiiConfirmation';
import ProgramSelect from '../ProgramSelect';
import Steps, { Step } from '../Steps';
import { StepTypes } from '../Steps/types';
import SubmissionError from '../SubmissionError';
import SubmissionLoader from '../SubmissionLoader';
import { FlowType } from './types';

type Props = {
  result: VoyagerResult;
  onFormSubmit: (result: VoyagerResult) => Promise<number | undefined>;
  prqSchema?: any;
  spotlightMatch?: VoyagerResult;
  recommenderMatches?: VoyagerResult[];
  userInputs: VoyagerInputs;
  onProgramCtaClick: (result: VoyagerResult) => void;
  initialStep: StepTypes;
  flowType: FlowType;
  onFlowExit: () => void;
  onEdit: (result: VoyagerResult) => void;
  publisher: string;
  currentResultApplicationCta: ApplicationCta;
  isHeclidLoading: boolean;
  isCurrentResultApplicationCtaLoading: boolean;
  hasCurrentProgramBeenSubmitted?: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  degree: string;
  subject: string;
};

const ProgramSubmissionFlow: FC<Props> = ({
  userInputs,
  result,
  onFormSubmit,
  prqSchema,
  spotlightMatch,
  recommenderMatches,
  onProgramCtaClick,
  initialStep,
  flowType,
  onFlowExit,
  onEdit,
  publisher,
  currentResultApplicationCta,
  isHeclidLoading,
  isCurrentResultApplicationCtaLoading,
  hasCurrentProgramBeenSubmitted,
  userPreferencesAndMilitary,
  degree,
  subject,
}) => {
  const [currentStepId, setCurrentStepId] = useState(initialStep);
  const [isSubmittingLead, setIsSubmittingLead] = useState(false);

  const handleProgramSubmit = async () => {
    setIsSubmittingLead(true);
    if (prqSchema) {
      setCurrentStepId(StepTypes.ADDITIONAL_QUESTIONS);
      setIsSubmittingLead(false);
      return;
    }

    setCurrentStepId(StepTypes.LOADING);
    const responseStatus = await onFormSubmit(result);
    if (responseStatus === 201) {
      setCurrentStepId(StepTypes.EXIT_STRATEGY);
    } else if (responseStatus === 400) {
      setCurrentStepId(StepTypes.NOT_QUALIFIED);
    } else {
      setCurrentStepId(StepTypes.ERROR);
    }

    setIsSubmittingLead(false);
  };

  const handlePRQFormSubmit = async () => {
    setIsSubmittingLead(true);
    setCurrentStepId(StepTypes.LOADING);
    const responseStatus = await onFormSubmit(result);
    if (responseStatus === 201) {
      setCurrentStepId(StepTypes.EXIT_STRATEGY);
    } else if (responseStatus === 400) {
      setCurrentStepId(StepTypes.NOT_QUALIFIED);
    } else {
      setCurrentStepId(StepTypes.ERROR);
    }

    setIsSubmittingLead(false);
  };

  const handleProgramCtaClick = (result: VoyagerResult) => {
    onProgramCtaClick(result);
    setCurrentStepId(StepTypes.PII_CONFIRMATION);
  };

  const goToPogramSelect = () => setCurrentStepId(StepTypes.PROGRAM_SELECT);

  const goToPRQ = () => setCurrentStepId(StepTypes.ADDITIONAL_QUESTIONS);

  useEffect(() => {
    if (hasCurrentProgramBeenSubmitted) setCurrentStepId(StepTypes.EXIT_STRATEGY);
  }, [hasCurrentProgramBeenSubmitted]);

  return (
    <Steps currentStepId={currentStepId}>
      <Step stepId={StepTypes.PROGRAM_SELECT}>
        <ProgramSelect
          spotlightMatch={spotlightMatch as VoyagerResult}
          recommenderMatches={recommenderMatches as VoyagerResult[]}
          onFlowExit={onFlowExit}
          firstName={userInputs?.firstName?.value}
          onProgramCtaClick={handleProgramCtaClick}
          publisherName={publisher}
          userPreferencesAndMilitary={userPreferencesAndMilitary}
          degree={degree}
          subject={subject}
        />
      </Step>
      <Step stepId={StepTypes.PII_CONFIRMATION}>
        <PiiConfirmation
          flowType={flowType}
          result={result}
          onFormSubmit={handleProgramSubmit}
          onBackButtonClick={goToPogramSelect}
          onEdit={onEdit}
          userInputs={userInputs}
          isHeclidLoading={isHeclidLoading}
          isSubmittingLead={isSubmittingLead}
          setIsSubmittingLead={setIsSubmittingLead}
        />
      </Step>
      <Step stepId={StepTypes.ADDITIONAL_QUESTIONS}>
        <AdditionalQuestions2
          flowType={flowType}
          selectedResult={result}
          prqSchema={prqSchema}
          onFormSubmit={handlePRQFormSubmit}
          eventingOverrides={{}}
          onBackButtonClick={() => handleProgramCtaClick(result)}
          result={result}
          isSubmittingLead={isSubmittingLead}
        />
      </Step>

      <Step stepId={StepTypes.EXIT_STRATEGY}>
        <ExitStrategyComponent
          onBackButtonClick={goToPRQ}
          result={result}
          firstName={userInputs?.firstName?.value}
          flowType={flowType}
          currentResultApplicationCta={currentResultApplicationCta}
          isCurrentResultApplicationCtaLoading={isCurrentResultApplicationCtaLoading}
        />
      </Step>

      <Step stepId={StepTypes.NOT_QUALIFIED}>
        <NotQualified flowType={flowType} result={result} onCtaClick={onFlowExit} />
      </Step>

      <Step stepId={StepTypes.LOADING}>
        <SubmissionLoader flowType={flowType} result={result} />
      </Step>

      <Step stepId={StepTypes.ERROR}>
        <SubmissionError flowType={flowType} onCtaClick={onFlowExit} />
      </Step>
    </Steps>
  );
};

export default ProgramSubmissionFlow;
