import { track } from '@core/services/cohesion';
import { VoyagerResult } from '@core/ts/results';

type EventingOverrides = {
  location?: string;
  formatSubtype?: string;
  customDimensions?: { key: string; value: string }[];
};

const buildProductPayload = (result: VoyagerResult, eventingOverrides?: EventingOverrides) => {
  const payload = {
    listId: result?.eventing?.listId,
    viewCorrelationId: result?.eventing?.viewCorrelationId,
    product: {
      sku: String(result.cap.id),
      variant: result.program.degree.slug,
      productId: String(result.program.id),
      name: result.program.subject.slug,
      category: result.program.category.slug,
      brand: result.school.slug,
      position: result?.eventing?.position,
      location: eventingOverrides?.location ?? 'voyager-results-page',
      formatType: 'app',
      formatSubtype: eventingOverrides?.formatSubtype ?? 'grid',
      positionEngine: 'algolia',
    },
    customDimensions: eventingOverrides?.customDimensions ?? [],
  };
  return payload;
};

const productViewed = (result: VoyagerResult, eventingOverrides: EventingOverrides) => {
  track({ eventName: 'ProductViewed', properties: buildProductPayload(result, eventingOverrides) });
};

const productLoaded = (result: VoyagerResult, eventingOverrides: EventingOverrides) => {
  track({ eventName: 'ProductLoaded', properties: buildProductPayload(result, eventingOverrides) });
};

const productClicked = (result: VoyagerResult, eventingOverrides: EventingOverrides) => {
  track({ eventName: 'ProductClicked', properties: buildProductPayload(result, eventingOverrides) });
};

export { productViewed, productLoaded, productClicked };
