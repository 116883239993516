import { type FC, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import useFormEvents from '@core/hooks/cohesion/useFormEvents';
import { setInputs, type VoyagerInputs } from '@core/reducers/inputsSlice';
import { validateEmail, validatePhone } from '@core/services/leadDelivery';
import type { VoyagerResult } from '@core/ts/results';

import { FlowType } from '../ProgramSubmissionFlow/types';
import PiiConfirmationDrawer from './PiiConfirmationDrawer';
import PiiConfirmationPopup from './PiiConfirmationPopup';

type Props = {
  onFormSubmit: () => void;
  onBackButtonClick: () => void;
  flowType: FlowType;
  result: VoyagerResult;
  onEdit: (result: VoyagerResult) => void;
  userInputs: VoyagerInputs;
  isHeclidLoading: boolean;
  isSubmittingLead: boolean;
  setIsSubmittingLead: Dispatch<SetStateAction<boolean>>;
};

const isEmptyString = (str: String) => str === null || str?.match(/^ *$/) !== null;

const PiiConfirmation: FC<Props> = ({
  result,
  flowType,
  userInputs,
  onEdit,
  onFormSubmit,
  onBackButtonClick,
  isHeclidLoading,
  isSubmittingLead,
  setIsSubmittingLead,
}) => {
  const dispatch = useDispatch();
  const PiiConfirmationComponent = flowType === FlowType.POPUP ? PiiConfirmationPopup : PiiConfirmationDrawer;
  const [isEditing, setIsEditing] = useState(false);
  const [piiErrors, setPiiLeadErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  const { formErrored } = useFormEvents({
    formContext: {
      formName: 'voyager',
      formVersion: 'dynamic-post-flow',
      formType: 'post-flow',
      formBrand: result.school?.slug,
      formId: '1015',
    },
    errorDetails: 'INVALID',
    errorMessage: 'Please provide your valid phone number.',
    errorType: 'Phone Validation',
  });

  const [pii, setPii] = useState<Record<string, string>>({
    firstName: userInputs?.firstName?.value,
    lastName: userInputs?.lastName?.value,
    email: userInputs?.email?.value,
    phone: userInputs?.phone?.value,
  });

  const savePii = useCallback(() => {
    const { firstName, lastName, email, phone } = pii;
    dispatch(
      setInputs([
        { key: 'firstName', value: firstName },
        { key: 'lastName', value: lastName },
        { key: 'email', value: email },
        { key: 'phone', value: phone },
      ])
    );
  }, [dispatch, pii]);

  const checkForPiiErrors = useCallback(async (): Promise<boolean> => {
    const formErrors = {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
    };

    setPiiLeadErrors(formErrors);

    const { firstName, lastName, email, phone } = pii;

    if (isEmptyString(firstName)) formErrors.firstName = true;
    if (isEmptyString(lastName)) formErrors.lastName = true;
    if (isEmptyString(email) || !validateEmail(email ?? '')) formErrors.email = true;

    if (isEmptyString(phone)) {
      formErrors.phone = true;
    } else {
      const { validPhoneNumber } = await validatePhone(phone);
      if (!validPhoneNumber) {
        formErrors.phone = true;
        formErrored();
      }
    }

    const hasErrors = Object.values(formErrors).some((error) => error);

    if (hasErrors) {
      setPiiLeadErrors(formErrors);
      return true;
    }
    savePii();

    return false;
  }, [formErrored, pii, savePii]);

  const handleEdit = () => {
    setIsEditing(true);
    onEdit(result);
  };
  const handleEditConfirm = useCallback(async () => {
    const hasPiiErrors = await checkForPiiErrors();

    if (hasPiiErrors) return;
    setIsEditing(false);
    savePii();
  }, [checkForPiiErrors, savePii]);

  const handleFormSubmission = async () => {
    setIsSubmittingLead(true);
    const hasPiiErrors = await checkForPiiErrors();

    if (hasPiiErrors) return;

    onFormSubmit();
  };

  return (
    <PiiConfirmationComponent
      onFormSubmit={handleFormSubmission}
      isHeclidLoading={isHeclidLoading}
      pii={pii}
      result={result}
      piiErrors={piiErrors}
      setPii={setPii}
      isEditing={isEditing}
      onEdit={handleEdit}
      onEditConfirm={handleEditConfirm}
      onBackButtonClick={onBackButtonClick}
      isSubmittingLead={isSubmittingLead}
    />
  );
};

export default PiiConfirmation;
