import { type ButtonHTMLAttributes, type FC } from 'react';
import { HiCheckCircle } from 'react-icons/hi';

import cn from '@core/utils/classNames';

import Button from '../../ui/Button';
import ButtonLoader from '../ButtonLoader';
import styles from './FormSubmissionButton.module.css';

type Props = {
  isLoading?: boolean;
  isDisabled?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const FormSubmissionButton: FC<Props> = ({ isLoading = false, className = '', ...props }) => (
  <Button className={cn(className, styles.formSubmissionButton)} {...props} primary>
    <ButtonLoader isLoading={isLoading}>
      <div className={styles.formSubmissionButtonText} role="button">
        Learn More
      </div>
      <HiCheckCircle size={18} />
    </ButtonLoader>
  </Button>
);

export default FormSubmissionButton;
