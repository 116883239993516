import { useCallback, type FC } from 'react';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';
import type { VoyagerResult } from '@core/ts/results';

import { productViewed } from '../../services/events/productEvents';
import type { UserPreferencesAndMilitary } from '../../types';
import RecommenderProgramSelect from './RecommenderProgramSelect';
import SpotlightProgramSelect from './SpotlightProgramSelect';

type Props = {
  spotlightMatch: VoyagerResult;
  onFlowExit: () => void;
  firstName?: string;
  onProgramCtaClick: (result: VoyagerResult) => void;
  recommenderMatches: VoyagerResult[];
  publisherName: string;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  degree: string;
  subject: string;
};

const ProgramSelect: FC<Props> = ({
  spotlightMatch,
  recommenderMatches,
  userPreferencesAndMilitary,
  degree,
  subject,
  onProgramCtaClick,
  onFlowExit,
  ...otherProps
}) => {
  const { elementClicked } = useElementEvents();

  const location = spotlightMatch ? 'voyager-spotlight-pop-up' : 'voyager-recommender-pop-up';

  const ProgramSelectComponent = spotlightMatch ? SpotlightProgramSelect : RecommenderProgramSelect;

  const handleCardView = useCallback(
    (result: VoyagerResult) => {
      productViewed(result, { location });
    },
    [location]
  );

  const handleFlowExit = () => {
    elementClicked({
      webElement: {
        elementType: 'exit-element',
        text: 'Back to All Results',
        htmlId: 'exit-back',
        name: 'exit-application-step',
        location,
      },
    });
    onFlowExit?.();
  };

  return (
    <ProgramSelectComponent
      onProgramCtaClick={onProgramCtaClick}
      spotlightMatch={spotlightMatch}
      onView={handleCardView}
      recommenderMatches={recommenderMatches}
      onFlowExit={handleFlowExit}
      userPreferencesAndMilitary={userPreferencesAndMilitary}
      degree={degree}
      subject={subject}
      {...otherProps}
    />
  );
};

export default ProgramSelect;
