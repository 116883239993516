import { useCallback, type FC } from 'react';

import CustomTooltip from '@core/shared/components/CustomTooltip';
import type { VoyagerResult } from '@core/ts/results';
import cn from '@core/utils/classNames';

import {
  showAccredited,
  showApplyForFree,
  showFlexible,
  showMilitary,
  showNoActSat,
  showTransferCredits,
} from '../../UserPreferencesPills';
import { productClicked, productLoaded, productViewed } from '../../services/events/productEvents';
import type { ApplicationCta, UserPreferencesAndMilitary } from '../../types';
import ProgramCard from '../ProgramCard';
import { ProgramCardProps } from '../ProgramCard/types';
import styles from './ResultList.module.css';

type Props = {
  title?: string;
  description?: string;
  results?: VoyagerResult[];
  onProgramCardClick: (result: VoyagerResult) => void;
  cardType?: ProgramCardProps['type'];
  handleCheckIfProgramIsSubmitted?: (result: VoyagerResult) => boolean;
  handleFindProgramApplicationCta?: (result: VoyagerResult) => ApplicationCta;
  isCoveredByElement?: boolean;
  location?: string;
  formatSubtype?: string;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  customDimensions?: { key: string; value: string }[];
};

const ResultList: FC<Props> = ({
  title,
  description,
  results,
  onProgramCardClick,
  handleCheckIfProgramIsSubmitted,
  handleFindProgramApplicationCta,
  cardType = 'default',
  isCoveredByElement = false,
  location,
  formatSubtype,
  userPreferencesAndMilitary,
  customDimensions = [],
}) => {
  const pillCustomDimensions = (result: VoyagerResult) => [
    {
      key: 'apply-for-free',
      value: showApplyForFree(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
    {
      key: 'accredited',
      value: showAccredited(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
    {
      key: 'no-act-sat',
      value: showNoActSat(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
    {
      key: 'credit-transfer',
      value: showTransferCredits(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
    {
      key: 'flexible',
      value: showFlexible(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
    {
      key: 'military',
      value: showMilitary(result, userPreferencesAndMilitary) ? 'true' : 'false',
    },
  ];

  const handleCardMount = useCallback(
    (result: VoyagerResult) => {
      productLoaded(result, {
        location,
        formatSubtype,
      });
    },
    [customDimensions, formatSubtype, location]
  );

  const handleCardView = useCallback(
    (result: VoyagerResult) => {
      productViewed(result, {
        location,
        formatSubtype,
        customDimensions: [...customDimensions, ...pillCustomDimensions(result)],
      });
    },
    [customDimensions, formatSubtype, location]
  );

  const handleCardCtaClick = useCallback(
    (result: VoyagerResult) => {
      productClicked(result, {
        location,
        formatSubtype,
        customDimensions: [...customDimensions, ...pillCustomDimensions(result)],
      });

      onProgramCardClick?.(result);
    },
    [location, formatSubtype, customDimensions, onProgramCardClick]
  );

  return results?.length ? (
    <section className={styles.resultList}>
      {(title || description) && (
        <header className={styles.resultListHeader}>
          <h2>{title}</h2>
          <div className={styles.descriptionContainer}>
            {description && <span className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />}
            <div className={styles.tooltipContainer}>
              <CustomTooltip
                className={styles.tooltip}
                tooltipTitle=" Learn More About Our Partners"
                text={`${window.location.host} is an advertising-supported site. Featured or trusted partner programs and all school search, finder, or match results are for schools that compensate us. This compensation does not influence our school rankings, resource guides, or other editorially-independent information published on this site.`}
                title="About Our Partner Schools"
              />
            </div>
          </div>
        </header>
      )}

      <div className={cn(styles.resultListGrid, results?.length === 1 && styles.fullWidthCard)}>
        {results?.map((result) => (
          <ProgramCard
            type={cardType}
            key={result.program.id}
            result={result}
            onClick={handleCardCtaClick}
            onMount={handleCardMount}
            onView={handleCardView}
            handleCheckIfProgramIsSubmitted={handleCheckIfProgramIsSubmitted}
            handleFindProgramApplicationCta={handleFindProgramApplicationCta}
            isCoveredByElement={isCoveredByElement}
            userPreferencesAndMilitary={userPreferencesAndMilitary}
          />
        ))}
      </div>
    </section>
  ) : null;
};

export default ResultList;
