import { type FC } from 'react';

import BaseSubmissionError from './BaseSubmissionError';
import styles from './SubmissionError.module.css';

type Props = {
  onCtaClick: () => void;
};

const SubmissionErrorPopup: FC<Props> = ({ onCtaClick }) => (
  <div className={styles.sumbissionErrorPopupContainer}>
    <BaseSubmissionError onCtaClick={onCtaClick} />
  </div>
);

export default SubmissionErrorPopup;
