import { type FC } from 'react';

import styles from './SubmissionError.module.css';

type Props = {
  onCtaClick: () => void;
};

const BaseSubmissionError: FC<Props> = ({ onCtaClick }) => (
  <div className={styles.baseSubmissionError}>
    <section className={styles.content}>
      <h3>Error</h3>
      <p>An error ocurred. Please try again later!</p>
    </section>

    <button type="button" className={styles.backButton} onClick={onCtaClick}>
      Back to All Results
    </button>
  </div>
);

export default BaseSubmissionError;
