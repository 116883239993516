import { type FC } from 'react';

import HowWeSelectOurMatches from '../HowWeSelectOurMatchesCallout';
import Pill from '../Pill';
import styles from './UserPreferencesBanner.module.css';

type Props = {
  resultsCount: number;
  degreePill?: string;
  subjectPill?: string;
  userPreferences?: [{ key: string; label: string }];
};

const UserPreferencesBanner: FC<Props> = ({ resultsCount, degreePill, subjectPill, userPreferences }) => (
  <>
    <section className={styles.userPreferencesBanner}>
      <div className={styles.leftSide}>
        <p>Results based on my preferences:</p>
        <div className={styles.pills}>
          {degreePill && (
            <Pill color="white" className="text-white">
              {degreePill}
            </Pill>
          )}
          {subjectPill && <Pill color="white">{subjectPill}</Pill>}
          {userPreferences?.map((option: { key: string; label: string }) =>
            option.label === 'Ability to transfer credits' ? (
              <Pill color="#79B8DF" key={option.key} className="text-secondary-400">
                Ability to transfer credits
              </Pill>
            ) : (
              <Pill color="#79B8DF" key={option.key} className="text-secondary-400">
                {option.label}
              </Pill>
            )
          )}
        </div>
      </div>
      <div className={styles.rightSide}>
        <span className={styles.resultsTitle}>{resultsCount} total results</span>

        <HowWeSelectOurMatches />
      </div>
    </section>
  </>
);

export default UserPreferencesBanner;
