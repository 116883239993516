import { type FC } from 'react';

import type { VoyagerResult } from '@core/ts/results';

import type { UserPreferencesAndMilitary } from '../../types';
import ResultList from '../ResultList';
import styles from './EmbeddedRecommender.module.css';

type Props = {
  firstName?: string;
  subject?: string;
  results?: VoyagerResult[];
  onProgramCardClick: (result: VoyagerResult) => void;
  isCoveredByElement?: boolean;
  userPreferencesAndMilitary: UserPreferencesAndMilitary;
  handleCheckIfProgramIsSubmitted: (result: VoyagerResult) => boolean;
};

type NoResultsProps = {
  firstName?: string;
};

const NoResults: FC<NoResultsProps> = ({ firstName }) => (
  <>
    <header>
      <h2>
        {firstName ? `Sorry, ${firstName}!` : 'Sorry!'} <br /> We couldn&apos;t find any matches for you right now.
      </h2>
      <span>We&apos;re continually updating our inventory. Come back soon or retake our quiz.</span>
    </header>
  </>
);

const location = 'voyager-embedded-recommender';
const customDimensions = [{ key: 'isUserRequested', value: 'true' }];

const EmbeddedRecommender: FC<Props> = ({
  firstName,
  subject,
  results,
  onProgramCardClick,
  isCoveredByElement,
  userPreferencesAndMilitary,
  handleCheckIfProgramIsSubmitted,
}) => (
  <section className={styles.embeddedRecommender}>
    <div className={styles.embeddedRecommenderContainer}>
      {results?.length ? (
        <>
          <header>
            <span>Hi {firstName}! Ready to switch careers?</span>
            <h2>
              Your Top Matches for <br /> {subject}
            </h2>
          </header>
          <div className={styles.embeddedRecommenderResults}>
            <ResultList
              results={results}
              onProgramCardClick={onProgramCardClick}
              isCoveredByElement={isCoveredByElement}
              location={location}
              formatSubtype="grid"
              customDimensions={customDimensions}
              userPreferencesAndMilitary={userPreferencesAndMilitary}
              handleCheckIfProgramIsSubmitted={handleCheckIfProgramIsSubmitted}
            />
          </div>
        </>
      ) : (
        <NoResults firstName={firstName} />
      )}
    </div>
  </section>
);

export default EmbeddedRecommender;
