import { ButtonHTMLAttributes, useEffect, type FC, type KeyboardEvent } from 'react';
import { HiOutlineClock, HiOutlineClipboardList } from 'react-icons/hi';

import useElementEvents from '@core/hooks/cohesion/useElementEvents';

import ApplicationCtaButton from '../CtaButtons/ApplicationCtaButton';
import Pill from '../Pill';
import styles from './ApplicationCTA.module.css';

type Props = {
  cta: {
    url: string;
  };
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ApplicationCTA: FC<Props> = ({ cta, isLoading }) => {
  const { elementViewed, elementClicked } = useElementEvents({
    webElement: {
      elementType: 'linkout-cta',
      text: 'Start My Application',
      location: 'drawer',
      htmlId: 'voyager-application-cta',
      name: 'application-cta',
    },
  });

  const onCtaClick = () => {
    elementClicked();
  };

  const handleCtaEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onCtaClick?.();
    }
  };

  useEffect(() => {
    if (cta?.url) {
      elementViewed();
    }
  }, [cta, elementViewed]);

  return (
    (cta && cta?.url !== '' && (
      <div className={styles.applicationCTAContainer}>
        <Pill className={styles.pill}>
          <HiOutlineClock />
          <span>Accepting Students Now</span>
        </Pill>
        <div className={styles.cta}>
          <HiOutlineClipboardList />
          <span>Apply Online Today</span>
        </div>
        <p>Online applications are fast, convenient, and let you track your acceptance status. Start yours today!</p>

        <ApplicationCtaButton
          className={styles.ctaButton}
          onClick={() => {
            window.open(cta?.url, '_blank');
          }}
          isLoading={isLoading}
        >
          <div className={styles.ctaLabel} role="button" onClick={onCtaClick} onKeyDown={handleCtaEnter} tabIndex={0}>
            Start Your Application
          </div>{' '}
          +
        </ApplicationCtaButton>
      </div>
    )) ||
    null
  );
};
export default ApplicationCTA;
